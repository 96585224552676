import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"

import Img from "gatsby-image"

import { 
  AboutContainer, 
  ImageContainer, 
  Paragraph,
  FlexBlogContainer,
} from '../styles/blog';

export default function About({data, location}) {

  if (typeof window !== 'undefined'){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : 'gtm.dom',
      'pageType' : 'Sobre',
      'hostName' : 'blog.rocky.ag',
      'pagePath' : location.pathname,
      'pageReferrer' : document.referrer,
    });
  }

  return (


    <Layout location={location}>
      <SEO title="Sobre Nós" />
      <Header location={location} borderRadius={true}/>
      <FlexBlogContainer>
        <AboutContainer>
          <ImageContainer> 
            <Img 
              fluid={data.allFile.edges[0].node.childImageSharp.fluid} 
              style={{borderTopLeftRadius: "20px", borderTopRightRadius: "20px", imagePosition: "70% 50%"}}
            />
          </ImageContainer>
          <Paragraph>
            Fundada por ex-funcionários do Google e especializada em Marketing Digital.
            <br />
            <br />
            <b>Nosso objetivo é gerar resultados através de campanhas de performance no meio online e construir relacionamentos duradouros com nossos clientes.</b>
            <br />
            <br />
            Marketing Digital deve ser feito com base em dados. Por isso, nossa equipe é composta por engenheiros e matemáticos, com perfil analítico e conhecimentos estatísticos para tomarem as melhores decisões para nossos clientes.

            Somos especialistas em diversas plataformas para executar estratégias inteligentes independentemente do segmento, público ou objetivo que se deseja alcançar.
          </Paragraph>
        </AboutContainer>
      </FlexBlogContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {name: {eq: "ring2"}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid (maxWidth: 1700, maxHeight: 550) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
